import React, { useEffect } from 'react'
import { useUser } from '../context/UserContext'
import { User } from '../services/Api/User'
import { Agreement } from '../services/Api/Agreement'
import { Unit } from '../services/Api/Unit'
import { navigate } from 'gatsby'
import {clearAccessTokens} from '../utils/accessTokens'


const renderRentalAgreements = (rentalAgreements: any[]) => {
    return rentalAgreements.map((agreement) => {
        return <div key={agreement.RentalAgreementId}>
            <p>{"Avtalenummer: " + agreement.RentalAgreementId}</p>
            <p>{"Lagernummer: " + agreement.StorageUnitId}</p>
            <p>{"Størrelse: " + agreement.SizeCat}</p>
            <button onClick={() => destroyRentalAgreement(agreement.StorageUnitId)}>Avslutt leieavtale</button>
        </div>
    })
}

const orderStorageUnit = (StorageUnitId) =>{
    console.log('Bestiller enhet '+StorageUnitId)
    Agreement.new(StorageUnitId)
}
const destroyRentalAgreement = (StorageUnitId) =>{
    console.log('Avbestiller enhet '+StorageUnitId)
    Agreement.destroy(StorageUnitId)
}

const renderAvailableUnits = (availableUnits: any[]) => {
    return availableUnits.map((unit) =>{
        return <div key={unit.StorageUnitId}>
            <p>{"EnhetsID: "+unit.StorageUnitId+", Størrelse: "+unit.SizeCat}
            <button onClick={() => orderStorageUnit(unit.StorageUnitId)}>Bestill lager</button></p>
        </div>
    })
}

const MinSide = () => {
    const {user,setUser} = useUser()
    const [rentalAgreements, setRentalAgreements] = React.useState(null)
    const [availableUnits, setAvailableUnits] = React.useState(null)

    const logout = () => {
        clearAccessTokens()
        setUser({loggedIn:false})
        navigate('/')
    }

    const fetchUserData = () => {
        const userData = User.index()
        .then((res) => {
            console.log(res)
            setUser({...res.data,loggedIn:true})
        }).catch((error) => {
            console.log(error);
        })
    }

    const fetchRentalAgreements = () => {
        const rentalAgreements = Agreement.index()
        .then((res) => {
            console.log(res)
            setRentalAgreements(res.data)
        }).catch((error) => {
            console.log(error);
            
        })
    }

    const fetchAvailableUnits = () => {
        const availableUnits = Unit.available()
        .then((res) => {
            setAvailableUnits(res.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() =>{
        fetchUserData()
        fetchRentalAgreements()
        fetchAvailableUnits()
    },[])


    return (<div>
        <p>{user.userId}</p>
        <p>{user.userName}</p>
        <p>{user.Email}</p>
        <p>{user.accessToken}</p>
        {rentalAgreements != null ? renderRentalAgreements(rentalAgreements): 'Ingen leieavtaler'}
        {availableUnits != null ? renderAvailableUnits(availableUnits): 'Ingen ledige enheter'}
        <button onClick={logout}>logg ut</button>
    </div>)
}

export default MinSide