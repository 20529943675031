import axios from 'axios'
import {useUser} from '../../context/UserContext'


const apiClient = axios.create({
    baseURL: process.env.GATSBY_API_URL
})

apiClient.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken')
    console.log("Sending request with access token "+accessToken)

    return ({
        ...config,
        headers: {
            authorization: 'Bearer '+accessToken
        }
    })
},
error => Promise.reject(error)
)

apiClient.interceptors.response.use((response) => {
    return response
},
(error) => {
    return new Promise((resolve) => {
        const originalRequest = error.config
        const refreshToken = localStorage.getItem('refreshToken')
        if (error.response && error.response.status == 401 && error.config && !error.config._isRetryRequest && refreshToken){
            originalRequest._retry = true
            console.log('attempting to use refresh token')
            const response = fetch(process.env.GATSBY_API_URL+'/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: refreshToken
                })
            }).then((res) => res.json())
            .then((res) => {
                console.log('new access token: '+res.accessToken)
                localStorage.setItem('accessToken', res.accessToken)
                console.log(localStorage.getItem('accessToken'))
                originalRequest.headers.authorization = 'Bearer '+res.accessToken
                return axios(originalRequest)
            })
        
            resolve(response)
        }
    })
})

const {get, post,put, delete: destroy } = apiClient
export  {get, post, put, destroy };