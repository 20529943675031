import React from "react"
import Link  from "../components/Link"
import Map from "../components/Map"
import {UserContext, useUser} from "../context/UserContext"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ProductDisplay from "../components/productDisplay"
import { Button, makeStyles } from "@material-ui/core"
import PeepHole from "../components/peephole"
import LoginForm from "../components/forms/LoginForm"
import MinSide from "../components/minSide"



const IndexPage = () => {
  const {user,setUser} = useUser()
  
  if (!user.loggedIn && typeof localStorage !== 'undefined' && localStorage.getItem('accessToken')){
    setUser({loggedIn:true})
  }

  return (
  <Layout>
    <SEO title="Min side" />
    {!user.loggedIn ?
    <>
    <LoginForm/>
    <Link to={"/register"}>Registrer dykk!</Link>
    </>
    : <MinSide/>}
    
  </Layout>)
}

export default IndexPage
