import { Formik } from 'formik'
import React from 'react'
import MyTextField from './MyTextField'
import { Button } from '@material-ui/core'
import { useUser } from '../../context/UserContext'
import { Login } from '../../services/Api/Login'
import { setAccessTokens } from '../../utils/accessTokens'

const LoginForm = () => {

    const {user, setUser} = useUser()

    return (
        <Formik initialValues={{Email: "",Password:""}}
        onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true)
            Login.post(data)
            .then((res) => {
                setAccessTokens(res.data)
                setUser({...user,loggedIn:true})
            }).catch((err) =>
                console.log(err)
            )
            setSubmitting(false)
        }}
        >{({values, errors, handleChange, handleBlur, handleSubmit}) => (
            <form onSubmit={handleSubmit}>
            <MyTextField label="E-post" name="Email" type="input" variant="filled"/>
            <MyTextField label="Passord" name="Password" type="input" variant="filled"/>
            <Button variant='contained' color='primary' type="Send">Submit</Button>
            </form>)}
        </Formik>
        
    )
}
export default LoginForm