interface tokenObject {
    accessToken: string,
    refreshToken: string
}

export const setAccessTokens = (tokens: tokenObject) =>{
    localStorage.setItem('accessToken', tokens.accessToken)
    localStorage.setItem('refreshToken', tokens.refreshToken)
}

export const clearAccessTokens = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
}